import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { MdClose } from "react-icons/md";

export default function DifficultySelectModal({
  openDifficultySelectModal,
  setOpenDifficultySelectModal,
  setBotDifficulty,
}: any) {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={openDifficultySelectModal}
      onClose={undefined}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "20px",
      }}
      >
        <Paper 
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            position: "relative",
            width: { xs: 250, lg: 500 },
            borderRadius: "md",
            p: { sm: 1, lg: 5 },
            boxShadow: "lg",
            background: "#F2F5F9",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "25px",
            }}
          >
            Select Difficulty
          </Typography>
          <Box
            sx={{
                display: "flex",
                gap: "10%",
              }} 
          >
            <Button
              sx={{
                color: "black",
                minWidth: 0,
                width: { xs: "100%", lg: "100%" },
                height: { xs: "100%", lg: "100%" },
                justifyContent: "center",
                alignItems: "center",
                background: "#00C853",
                borderRadius: "5px",
                fontFamily: " 'Urbanist', sans-serif",
                fontStyle: "normal",
                fontSize: { xs: "7px", lg: "14px" },
                fontWeight: 700,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#00B347",
                },
              }}
              onClick={() => {
                setBotDifficulty(1);
                setOpenDifficultySelectModal(false);
              }}
            >
              Beginner
            </Button>
            <Button
              sx={{
                color: "black",
                minWidth: 0,
                width: { xs: "100%", lg: "100%" },
                height: { xs: "100%", lg: "100%" },
                justifyContent: "center",
                alignItems: "center",
                background: "#F44336",
                borderRadius: "5px",
                fontFamily: " 'Urbanist', sans-serif",
                fontStyle: "normal",
                fontSize: { xs: "7px", lg: "14px" },
                fontWeight: 700,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#D32F2F",
                },
              }}
              onClick={() => {
                setBotDifficulty(2);
                setOpenDifficultySelectModal(false);
              }}
            >
              Advanced
            </Button>
          </Box>
        </Paper>
      </Modal>
  );
};